import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formatDate, formValidatorRules } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import CreateSenderModal from './components/CreateSenderModal';
import {
  CREATE_SENDER,
  DELETE_SENDER,
  UPDATE_SENDER,
  VERIFY_SENDER
} from './graphql/Mutation';
import { SENDERS } from './graphql/Queries';
import './senders.less';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10
};

const Senders = ({ activeKey }) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext);
  const userRole = getCurrentRole();
  const { email } = formValidatorRules;
  const [sortedInfo, setSortedInfo] = useState({});
  const [isEmptySenderList, setIsEmptySenderList] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [senderList, setSenderList] = useState([]);
  const [senderSearchTerm, setSenderSearchTerm] = useState('');
  const [isSenderLoading, setIsSenderLoading] = useState(true);
  const [isEditSenderModal, setIsEditSenderModal] = useState(false);
  const [isEditSenderRowData, setIsEditSenderRowData] = useState({});
  const [isCreateSenderModalOpen, setCreateSenderModalOpen] = useState(false);
  const [isCreateSenderBtnLoading, setIsCreateSenderBtnLoading] = useState(
    false
  );
  const [isAllowClear, setIsAllowClear] = useState(false);
  const [isSenderSearchLoading, setIsSenderSearchLoading] = useState(false);

  const limit = 10;

  const [executeCreateSender] = useMutation(CREATE_SENDER, {
    onError: () => {}
  });
  const [executeUpdateSender] = useMutation(UPDATE_SENDER, {
    onError: () => {}
  });
  const [executeDeleteSender] = useMutation(DELETE_SENDER, {
    onError: () => {}
  });

  const [executeSenderList] = useLazyQuery(SENDERS, {
    onCompleted: (response) => {
      setSenderList([...response?.senders?.senders]);
      if (
        response?.senders?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptySenderList(true);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.senders?.count
      };
      setPaginationProp(pagination);
      setIsSenderLoading(false);
      setIsSenderSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsSenderLoading(false);
    }
  });

  const [executeVerifySender] = useMutation(VERIFY_SENDER, {
    onCompleted: (response) => {
      if (response?.verifySender?.status === 'SUCCESS') {
        setIsSenderLoading(true);
        executeSenderList({
          variables: {
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL',
            filter: {
              skip: 0,
              limit,
              sortBy: 'createdAtDESC'
            }
          }
        });
      }
    },
    onError: () => {}
  });

  useEffect(() => {
    if (state?.projectEnvId && activeKey === ROUTES?.SENDERS) {
      setIsSenderLoading(true);
      setSenderList([]);
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip: 0,
            limit,
            sortBy: 'createdAtDESC'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, activeKey]);

  const handleReset = () => {
    form?.resetFields();
  };

  const createSenderFun = async (senderDetail) => {
    setIsCreateSenderBtnLoading(true);
    const response = await executeCreateSender({
      variables: {
        data: {
          name: senderDetail?.name,
          email: senderDetail?.email?.trim(),
          projectEnvId: state?.projectEnvId
        }
      }
    });
    if (response?.data) {
      setCreateSenderModalOpen(false);
      setIsSenderLoading(true);
      setSenderList([]);
      setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
      setSenderSearchTerm('');
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setTimeout(handleReset, 500);
    }
    setIsCreateSenderBtnLoading(false);
  };

  const updateSenderFun = async (senderDetail) => {
    setIsCreateSenderBtnLoading(true);
    const response = await executeUpdateSender({
      variables: {
        data: {
          name: senderDetail?.name
        },
        id: isEditSenderRowData?.id
      }
    });
    if (response?.data) {
      setCreateSenderModalOpen(false);
      setIsSenderLoading(true);
      setSenderList([]);
      setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
      setSenderSearchTerm('');
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setTimeout(handleReset, 500);
    }
    setIsEditSenderRowData({});
    setIsCreateSenderBtnLoading(false);
  };

  const deleteSenderFun = async (id) => {
    const response = await executeDeleteSender({
      variables: {
        id: id
      }
    });
    if (response?.data) {
      setIsSenderLoading(true);
      setSenderList([]);
      const lastPageEle =
        paginationProp?.total % paginationProp?.pageSize === 1 &&
        paginationProp?.total > 10;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current: paginationProp?.current - 1
        });
      }
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip: lastPageEle
              ? (paginationProp?.current - 1) *
                  (paginationProp?.pageSize || 0) -
                paginationProp?.pageSize
              : (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: senderSearchTerm,
            sortBy: 'createdAtDESC'
          }
        }
      });
    }
  };

  const handleVerification = (record) => {
    executeVerifySender({
      variables: {
        id: record?.id,
        reVerify: false
      }
    });
  };

  const handleReVerify = async (record) => {
    await executeVerifySender({
      variables: {
        id: record?.id,
        reVerify: true
      }
    });
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsSenderLoading(true);
    setSenderList([]);
    if (sorter?.column) {
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: senderSearchTerm,
            sortBy:
              sorter?.order === 'ascend'
                ? `${sorter?.field}${SORT?.ASC}`
                : `${sorter?.field}${SORT?.DESC}`
          }
        }
      });
    } else {
      executeSenderList({
        variables: {
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL',
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: senderSearchTerm,
            sortBy: 'createdAtDESC'
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    setIsSenderSearchLoading(true);
    const trimValue = value?.trim();
    setSenderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsSenderLoading(true);
    setSenderList([]);
    executeSenderList({
      variables: {
        projectEnvId: state?.projectEnvId,
        type: 'EMAIL',
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          sortBy: 'createdAtDESC'
        }
      }
    });
    setIsAllowClear(false);
  };

  const AddSenderFormsItems = [
    {
      title: 'Email',
      name: 'email',
      placeholder: 'Enter email address',
      rules: [{ required: true, message: 'Please input email!' }, email]
    },
    {
      title: 'Name',
      name: 'name',
      placeholder: 'Enter name'
    }
  ];

  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record, index) => {
        return (
          <span>
            {paginationProp?.pageSize * paginationProp?.current -
              paginationProp?.pageSize +
              index +
              1}
          </span>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        return record?.name ? record?.name : '-';
      }
    },
    {
      title: 'EMAIl',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (_, record) => {
        return formatDate(record?.createdAt, 'MMM DD, YYYY hh:mm A');
      }
    },
    {
      title: 'VERIFICATION STATUS',
      dataIndex: 'verification_status',
      key: 'verification_status',
      render: (_, record) => {
        return (
          <>
            {record?.isVerified === true ? (
              <Button
                type="primary"
                className="primary-button verify-status verified-success pointer-none"
                disabled
              >
                Verified
              </Button>
            ) : (
              <>
                <Button
                  type="primary"
                  className="primary-button verify-status verification"
                  onClick={() => handleVerification(record)}
                >
                  Check Status
                </Button>
                <Button
                  type="primary"
                  className="secondary-button verify-status"
                  onClick={() => handleReVerify(record)}
                >
                  Re-verifiy
                </Button>
              </>
            )}
          </>
        );
      }
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record) => {
        return (
          <>
            <div className="action-button">
              <Button
                type="link"
                onClick={() => {
                  setIsEditSenderRowData(record);
                  setCreateSenderModalOpen(true);
                  setIsEditSenderModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.email}?`}
                onConfirm={() => deleteSenderFun(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      }
    }
  ]?.filter((item) => {
    if (
      (userRole === USERS_ROLE?.READ_ONLY && item?.dataIndex === 'actions') ||
      (userRole === USERS_ROLE?.READ_ONLY &&
        item?.dataIndex === 'verification_status')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptySenderList ? '' : <span />
  };

  return (
    <>
      <CreateSenderModal
        initialValues={isEditSenderRowData}
        isModalOpen={isCreateSenderModalOpen}
        setIsModalOpen={setCreateSenderModalOpen}
        formTitle={isEditSenderModal ? 'Update Sender' : 'Add Sender'}
        onFinish={createSenderFun}
        onUpdate={updateSenderFun}
        isEdit={isEditSenderModal}
        setIsEdit={setIsEditSenderModal}
        setIsEditSenderRowData={setIsEditSenderRowData}
        formItems={AddSenderFormsItems}
        submitButton={isEditSenderModal ? 'Update' : 'Add'}
        loadings={isCreateSenderBtnLoading}
        form={form}
        handleReset={handleReset}
      />
      <div className="sender">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            {senderList?.length > 0 ||
            senderSearchTerm ||
            isSenderSearchLoading ||
            isAllowClear ? (
              <SearchWithLoading
                setIsAllowClear={setIsAllowClear}
                query={senderSearchTerm}
                setQuery={setSenderSearchTerm}
                className="search-input"
                getData={handleSearch}
              />
            ) : null}
          </div>
          {senderList?.length > 0 ||
          senderSearchTerm ||
          isSenderSearchLoading ||
          isAllowClear ? (
            <>
              {(userRole === USERS_ROLE?.OWNER ||
                userRole === USERS_ROLE?.WRITE) && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() => {
                    setCreateSenderModalOpen(true);
                  }}
                >
                  Create New Sender
                </Button>
              )}
            </>
          ) : null}
        </div>
        <div className="mt-30 flex-vertical">
          {isSenderLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isSenderLoading}
            />
          ) : (
            <>
              {senderList?.length > 0 ||
              senderSearchTerm ||
              isSenderSearchLoading ||
              isAllowClear ? (
                <CommonTable
                  locale={locale}
                  columns={columns}
                  data={senderList}
                  loading={isSenderLoading}
                  onChange={handleTableChange}
                  paginationConfig={paginationProp}
                  rowKey={(record) => record?.id}
                />
              ) : (
                <div className="analytics-illustration">
                  <Nodata />
                  <br />
                  {(userRole === USERS_ROLE?.OWNER ||
                    userRole === USERS_ROLE?.WRITE) && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() => {
                        setCreateSenderModalOpen(true);
                      }}
                    >
                      Create New Sender
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Senders;
